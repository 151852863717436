import React, { useState, useEffect } from 'react';
import Toast from 'ui/Toast';
import { useTranslation } from 'react-i18next';

const NetworkStatus = ({ children }) => {
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div>
      {isOnline ? null : (
        <Toast variant="warning">
          <b>{t('Connection Issue')}</b>
          <br />

          {t(`We're having trouble connecting. Please check your network and try again later.`)}
        </Toast>
      )}

      {children}
    </div>
  );
};

export default NetworkStatus;
