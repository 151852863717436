import short from 'short-uuid';
import React, { useContext, useMemo, useState } from 'react';

import {
  usePassportRequests as usePassportRequestsService,
  usePassportOffers as usePassportOffersService
} from 'services/passport';

import { useUserSelector } from 'hooks/useUser';

const PassportContext = React.createContext(null);

export function PassportContextProvider({ children }) {
  const { user } = useUserSelector();
  const {
    data: passportRequests = [],
    isLoading: isPassportRequestsLoading,
    refetch: reloadPassportRequests
  } = usePassportRequestsService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const {
    data: passportOffers = [],
    isLoading: isPassportOffersLoading,
    refetch: reloadPassportOffers
  } = usePassportOffersService({
    enabled: !!passportRequests?.length
  });
  const [passportOffersLoadingEffect, setPassportOffersLoadingEffect] = useState(false);

  const hasUploadedDocuments = useMemo(() => {
    if (!passportRequests?.length) {
      return false;
    }

    const passportRequest = passportRequests?.[0];

    return !!(passportRequest?.id_document?.length || passportRequest?.proof_of_income?.length);
  }, [passportRequests]);

  const documentsUploadSecret = useMemo(() => {
    if (!passportRequests?.length) {
      return null;
    }

    const translator = short();
    const passportRequest = passportRequests?.[0];

    return translator.fromUUID(passportRequest.request_id);
  }, [passportRequests]);

  return (
    <PassportContext.Provider
      value={{
        passportRequests,
        isPassportRequestsLoading,
        reloadPassportRequests,

        passportOffers,
        isPassportOffersLoading,
        reloadPassportOffers,
        hasUploadedDocuments,

        passportOffersLoadingEffect,
        setPassportOffersLoadingEffect,

        documentsUploadSecret
      }}
    >
      {children}
    </PassportContext.Provider>
  );
}

export function usePassport() {
  const context = useContext(PassportContext);

  if (!context) {
    throw new Error('usePassport must be used within a PassportContextProvider');
  }

  return context;
}
